
.table-container {
  margin: 0 auto;
  width: 1080px; 
}

table {
  border-collapse: collapse;
  width: 100%
}

.main thead {
  background-color: lightblue;
}

.subtotal thead {
  background-color: pink;
}

tbody tr {
  border-bottom: 1px solid lightgrey;
  
}

tbody tr:hover {
  background-color: pink;
}